import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import { useSearchParamsStore } from "./searchParams";
import { CurrencySettings } from "@/types";

export const useCurrencyStore = defineStore("currency", () => {
  const { searchParams } = useSearchParamsStore();

  const getDefaultCurrency = (): CurrencySettings => {
    return {
      name: "Espees",
      code: "ESP",
      symbol: "ESP",
    };
  };

  const currencies = [
    {
      name: "Espees",
      code: "ESP",
      symbol: "ESP",
    },
    {
      name: "Naira",
      code: "NGN",
      symbol: "₦",
    },
    {
      name: "Pound sterling",
      code: "GBP",
      symbol: "£",
    },
    {
      name: "United States dollar",
      code: "USD",
      symbol: "$",
    },
  ] as const;

  const paramCurrency = computed(() => {
    if (!searchParams.currency) {
      return false;
    }
    const currency = currencies.find((c) => c.code === searchParams.currency);
    if (!currency) {
      return false;
    }

    return currency;
  });

  const defaultCurrency = computed<{ currency: CurrencySettings }>(() => {
    return paramCurrency.value
      ? { currency: paramCurrency.value }
      : { currency: getDefaultCurrency() };
  });

  const setDefaultCurrency = (currency: CurrencySettings) => {
    searchParams.currency = currency.code;
  };

  return { currencies, defaultCurrency, setDefaultCurrency };
});
